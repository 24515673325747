const ATTACHMENTS_DOCTYPES = ["Loan Request"]
const SAVE_ATTACHMENTS_DOCTYPES = ["Chart of Accounts Importer"]

frappe.ui.form.ControlAttach = class ControlAttach extends frappe.ui.form.ControlAttach {

    clear_attachment() {
        let me = this;
        if (this.frm) {
            let frmDoctype = this.frm.doctype
            me.parse_validate_and_set_in_model(null);
            me.refresh();
            if (ATTACHMENTS_DOCTYPES.includes(frmDoctype)) {
                let attachmentID
                if (me.doctype == frmDoctype) {
                    let attachIDFieldName = me.df.fieldname + "_id"
                    attachmentID = me.frm.doc[attachIDFieldName]
                    me.frm.set_value(attachIDFieldName, "")
                } else {
                    let attachTableFieldName = me.doc.parentfield
                    let rowIndex = me.doc.idx - 1
                    attachmentID = me.frm.doc[attachTableFieldName][rowIndex].attachment_id
                    me.frm.doc[attachTableFieldName][rowIndex].attachment_id = ""
                    me.frm.refresh_field(attachTableFieldName)
                }
                frappe.db.exists('File', attachmentID).then(exists => {
                    if (exists) {
                        frappe.db.delete_doc('File', attachmentID).then(r => {
                            me.refresh();
                        })
                    }
                })
            } else {
                me.frm.attachments.remove_attachment_by_filename(me.value, async () => {
                    await me.parse_validate_and_set_in_model(null);
                    me.refresh();
                    if (SAVE_ATTACHMENTS_DOCTYPES.includes(frmDoctype)) {
                        me.frm.doc.docstatus == 1 ? me.frm.save("Update") : me.frm.save();
                    }
                });
            }
        } else {
            this.dataurl = null;
            this.fileobj = null;
            this.set_input(null);
            this.parse_validate_and_set_in_model(null);
            this.refresh();
        }
    }
    async on_upload_complete(attachment) {
        let frmDoctype = this.frm.doctype
        if (this.frm) {
            await this.parse_validate_and_set_in_model(attachment.file_url);
            this.frm.attachments.update_attachment(attachment);
            if (SAVE_ATTACHMENTS_DOCTYPES.includes(frmDoctype)) {
                this.frm.doc.docstatus == 1 ? this.frm.save("Update") : this.frm.save();
            }
        }
        this.set_value(attachment.file_url);
        if (ATTACHMENTS_DOCTYPES.includes(frmDoctype)) {
            this.hide_reload_button()
            if (this.doctype == frmDoctype) {
                let attachIDFieldName = this.df.fieldname + "_id"
                this.frm.set_value(attachIDFieldName, attachment.name)
            } else {
                let attachTableFieldName = this.doc.parentfield
                let rowIndex = this.doc.idx - 1
                this.frm.doc[attachTableFieldName][rowIndex].attachment_id = attachment.name
                this.frm.refresh_field(attachTableFieldName)
            }
        }
    }
    hide_reload_button() {
        this.$value.find('[data-action="reload_attachment"]').hide();
    }
}
